import React from 'react'
import { User } from '../types/model'

import logo from './COPRA-logo-white.png'
import './Navigation.css'
import { format } from 'date-fns'
import { logoutUser } from '../state/asyncUserActions'

interface Props {
    user: User
}

export const Navigation = ({ user }: Props) => {
    return (
        <div>
            <div className="copra-top-nav">
                <div className="left-nav-item">Dashboard - {user.name}</div>
                <img src={logo} alt="COPRA Logo" className="nav-logo" />
                <div className="right-nav-items">
                    {format(Date.now(), 'EEEE, do MMMM yyyy')}
                    <br />
                    {user.loginTokenAuthorized || (
                        <button
                            onClick={(e) => logoutUser()}
                            className="nav-logout"
                        >
                            Log out
                        </button>
                    )}
                    <div className="report-issue-container">
                        <a href="mailto:copra@magno-it.nl?subject=COPRA issue">
                            Report issue
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}
