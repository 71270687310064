import { action } from './action'
import { authedGet, authedPost, authedPut } from "../lib/util/http";
import { Login, LoginResponse, SetPassword, SetPasswordResponse } from "../types/model";
import { navigate } from "./navigateActions";

export const loadUser = async () => {
  const userResult = await authedGet('users/current')
  if (userResult.status !== 200) {
    return action('USER_NOT_LOADED')
  }
  const user = await userResult.json()
  action('USER_LOADED', user)
}

export const loginUser = async (login: Login) => {
  const loginResult = await authedPost('users/login', login)
  if (loginResult.status !== 200) {
    const errorResponse = await loginResult.json()
    return action('LOGIN_FAILED', errorResponse.error)
  }
  const response: LoginResponse = await loginResult.json()
  localStorage.setItem('sessionId', response.token)
  
  await loadUser()

  setTimeout(() => {
    const redirect = localStorage.getItem('redirect')
    if (redirect) {
      localStorage.removeItem('redirect')
      window.location.href = redirect
    } else {
      navigate('home')
    }
  }, 10)
}

export const resetPasswordUser = async (email: string) => {
  const resetPasswordResult = await authedPut('users/reset-password', { email })
  if (resetPasswordResult.status !== 200) {
    return action('RESET_PASSWORD_FAILED')
  }
  const response = await resetPasswordResult.json()
  //console.log(response)
  action('RESET_PASSWORD_RESULT', { result: response.result })
}

export const logoutUser = () => {
  localStorage.removeItem('sessionId')
  localStorage.removeItem('nameFilter')
  navigate('login')
}

export const loadUserByToken = async (token: string) => {
  const userResult = await authedGet(`users/password-id/${token}`)
  if (userResult.status !== 200) {
    return action('USER_BY_TOKEN_FAILED')
  }
  const user = await userResult.json()
  action('USER_BY_TOKEN_LOADED', user)
}

export const setUserPasswordByToken = async (setPassword: SetPassword) => {
  const userResult = await authedPut(`users/password-id/${setPassword.token}`, {password: setPassword.password})
  const response: SetPasswordResponse = await userResult.json()
  if (userResult.status !== 200) {
    return action('USER_SET_PASSWORD_BY_TOKEN_ERROR', {error: response.error})
  }

  setTimeout(() => {
    navigate('login')
  }, 10)
}
