import React, { useState } from 'react'

import './ToolTip.css'
import icon_info from './icons/icon-info.png'

interface Props {
  text: string
  width?: number
}

export const ToolTip = ({ text, width }: Props) => {
  const [tooltipVisible, showToolTip] = useState(false)

  const style = {
    display: tooltipVisible ? 'block' : 'none',
    width: width || 250
  }
  
  return <div className='tooltip-container'>
    <img alt='' src={icon_info} onMouseOver={e => showToolTip(true)} onMouseOut={e => showToolTip(false)} />
    <div className='tooltip' style={style}>
      {text}
    </div>
  </div>
}
