import React from 'react'
import { TimeUnit } from '../types/model'
import { TextNumberInput } from './TextNumberInput'

interface Props {
    value: string
    unit: TimeUnit
    readonly: boolean
    onChange: (e: string) => void
}

export const TimeUnitInput = ({ value, unit, onChange, readonly }: Props) => {
    const _onChangeSelect = (e: any) => {
        const el = e.target as any
        const val = el.options[el.selectedIndex].value
        onChange(val)
    }

    switch (unit) {
        case 'days':
            return (
                <div>
                    <select
                        value={value}
                        onChange={_onChangeSelect}
                        disabled={readonly}
                    >
                        <option value="0"></option>
                        <option value="1">1</option>
                        <option value="0.75">0.75</option>
                        <option value="0.5">0.5</option>
                        <option value="0.25">0.25</option>
                    </select>
                </div>
            )
        default:
        case 'hours':
            return (
                <TextNumberInput
                    readonly={readonly}
                    value={value}
                    decimals={2}
                    onChange={onChange}
                />
            )
    }
}
