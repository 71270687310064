import store from './store'
import { Action } from "./types";

export const action = (type: string, payload: object | undefined = undefined) => {
  const action: Action = {type: type}
  if (payload) {
    action.payload = payload
  }
  store.dispatch(action)
}
