import { weekNumber } from 'weeknumber'
import { DayInput, DayTotals } from './types'

export const getWeekNumber = (date: string) => {
  return weekNumber(new Date(date))
}

const numberOrZero = (value: string) => {
  const num = Number(value)
  return isNaN(num) ? 0 : num
}

export const sumDayTotals = (days: DayInput[]): DayTotals => {
  const totalWorked = days.reduce((prev, cur) => numberOrZero(cur.worked || '') + prev, 0)
  const totalExpenses = days.reduce((prev, cur) => numberOrZero(cur.expenses || '') + prev, 0)
  const totalTravel = days.reduce((prev, cur) => numberOrZero(cur.travel || '') + prev, 0)
  const totalPaidLeave = days.reduce((prev, cur) => numberOrZero(cur.paidLeave || '') + prev, 0)
  const totalExpensesDeductible = days.reduce((prev, cur) => numberOrZero(cur.expensesDeductible || '') + prev, 0)
  const totalTravelDeductible = days.reduce((prev, cur) => numberOrZero(cur.travelDeductible || '') + prev, 0)
  const totalTravelExpensesDeductible = days.reduce((prev, cur) => numberOrZero(cur.travelExpensesDeductible || '') + prev, 0)
  return {
    totalWorked,
    totalExpenses,
    totalTravel,
    totalPaidLeave,
    totalExpensesDeductible,
    totalTravelDeductible,
    totalTravelExpensesDeductible
  }
}
