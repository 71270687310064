import * as immutable from 'object-path-immutable'
import { Action, AuthenticationState } from "./types";

interface LoginError {
  error: string
}

const initialState: AuthenticationState = {
}

export const authenticationReducer = (state: AuthenticationState = initialState, action: Action): AuthenticationState => {
  switch (action.type) {
    case 'USER_BY_TOKEN_LOADED':
      return immutable.set(state, 'userByToken', action.payload)
    case 'USER_BY_TOKEN_FAILED':
      return immutable.set(state, 'userByToken', undefined)
    case 'USER_SET_PASSWORD_BY_TOKEN_ERROR':
      const error = (action.payload as any).error
      return immutable.set(state, 'error', error)
    case 'RESET_PASSWORD_RESULT':
      const result = (action.payload as any).result
      return immutable.wrap(state)
        .set('error', '')
        .set('result', result)
        .value()
    case 'RESET_PASSWORD_FAILED':
      return immutable.wrap(state)
        .set('error', 'Failed to reset your password. Is the email address correct?')
        .set('result', '')
        .value()
    case 'LOGIN_FAILED':
      const err = action.payload as LoginError
      const message = err ? err : 'Login failed: incorrect credentials.'
      return immutable.wrap(state)
        .set('error', message)
        .set('result', '')
        .value()
    default:
      return state
  }
}
