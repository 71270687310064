import React, { useEffect, useState } from 'react'
import { TimesheetsState, AppState, Consentable } from '../state/types'
import {
    SortOrder,
    loadTimesheetsForUser,
    onDidNotWork,
} from '../state/asyncTimesheetActions'
import { Navigation } from './Navigation'

import './HomePage.css'
import '../lib/ui/Tabs.css'
import { Tab } from './Tab'
import { HomePageFilters } from './HomePageFilters'
import { TimesheetList } from './TimesheetList'
import { TimesheetState, User } from '../types/model'
import { navigate } from '../state/navigateActions'
import { ConsentableModal } from './ConsentableModal'
import {
    acceptConsentable,
    loadConsentablesForUser,
} from '../state/asyncDocumentActions'
import { Export } from './Export'
import { ApproveAllModal } from './ApproveAllModal'
import { userHasAnyOfRoles, userHasRole } from '../roles'

interface Props {
    timesheets: TimesheetsState
    app: AppState
}

const toBeProcessed = (state: TimesheetState) => {
    return ['submitted', 'approved', 'processing'].includes(state)
}

const getStartTab = (user: User) => {
    if (userHasRole(user, 'USER')) {
        return 'draft'
    }
    return 'toBeProcessed'
}

const PAGE_SIZE = 20
const didNotWorkWarning = `
Are you sure you did not work for this entire period? This will clear any entries for every day in this timesheet.`

export const HomePage = (props: Props) => {
    const user = props.app.user
    const [nameFilter, setNameFilter] = useState('')
    const [stateFilter, setStateFilter] = useState('all')
    const [tab, setTab] = useState(getStartTab(user!))
    const [page, setPage] = useState(0)
    const [endDateSortOrder, setEndDateSortOrder] = useState(
        'DESC' as SortOrder
    )
    const [consentables, setConsentables] = useState([] as Consentable[])
    const [userDismissedConsentables, setUserDismissedConsentables] =
        useState(false)
    const [approveAllModal, setApproveAllModal] = useState(false)

    const onApproveAll = () => {
        setApproveAllModal(true)
    }

    const numPages =
        props.timesheets.total && Math.ceil(props.timesheets.total / PAGE_SIZE)

    const onTab = (tabId: string) => {
        loadTimesheetsForUser(
            tabId,
            0,
            { stateFilter, nameFilter },
            endDateSortOrder
        )
        setTab(tabId)
        localStorage.setItem('tab', tabId)
        localStorage.setItem('page', '0')
        setPage(0)
    }

    const onPage = (page: number) => {
        loadTimesheetsForUser(
            tab,
            page,
            { stateFilter, nameFilter },
            endDateSortOrder
        )
        localStorage.setItem('page', String(page))
        setPage(page)
    }

    const onEndDateSorting = (sortOrder: SortOrder) => {
        loadTimesheetsForUser(tab, page, { stateFilter, nameFilter }, sortOrder)
        localStorage.setItem('endDateSortOrder', sortOrder)
        setEndDateSortOrder(sortOrder)
    }

    const onStateFilter = (stateFilter: string) => {
        loadTimesheetsForUser(
            tab,
            0,
            { stateFilter, nameFilter },
            endDateSortOrder
        )
        setStateFilter(stateFilter)
        localStorage.setItem('stateFilter', stateFilter)
        localStorage.setItem('page', '0')
        setPage(0)
    }

    const onNameFilter = (nameFilter: string) => {
        loadTimesheetsForUser(
            tab,
            0,
            { stateFilter, nameFilter },
            endDateSortOrder
        )
        setNameFilter(nameFilter)
        localStorage.setItem('nameFilter', nameFilter)
        localStorage.setItem('page', '0')
        setPage(0)
    }

    const onDidNotWorkButton = async (
        e: React.MouseEvent,
        timesheetId: string
    ) => {
        e.stopPropagation()
        if (window.confirm(didNotWorkWarning)) {
            await onDidNotWork(timesheetId)
            loadTimesheetsForUser(
                tab,
                0,
                { stateFilter, nameFilter },
                endDateSortOrder
            )
        }
    }

    const doLoadConsentsForUser = async () => {
        const response: Consentable[] = await loadConsentablesForUser()
        setConsentables(response)
    }

    const onConsentableAccept = async (url: string) => {
        const response = await acceptConsentable(url)
        setConsentables(response)
    }

    useEffect(() => {
        if (user) {
            const storedTab = localStorage.getItem('tab')
            if (storedTab !== null) {
                setTab(storedTab)
            }
            const storedStateFilter = localStorage.getItem('stateFilter')
            if (storedStateFilter !== null) {
                setStateFilter(storedStateFilter)
            }
            const storedEndDateSortOrder = localStorage.getItem(
                'endDateSortOrder'
            ) as SortOrder
            if (storedEndDateSortOrder != null) {
                setEndDateSortOrder(storedEndDateSortOrder)
            }
            const storedNameFilter = localStorage.getItem('nameFilter')
            if (storedNameFilter !== null) {
                setNameFilter(storedNameFilter)
            }
            const storedPage = localStorage.getItem('page')
            if (storedPage !== null) {
                setPage(Number(storedPage))
            }
            loadTimesheetsForUser(
                storedTab || tab,
                storedPage !== null ? Number(storedPage) : page,
                {
                    stateFilter: storedStateFilter || stateFilter,
                    nameFilter: storedNameFilter || nameFilter,
                },
                storedEndDateSortOrder || endDateSortOrder
            )

            doLoadConsentsForUser()
        } else {
            navigate('login')
        }
    }, [user])

    if (!user) {
        return <div>Please login first.</div>
    }
    const timesheets = props.timesheets.timesheets
    const isAdminOrApprover = userHasAnyOfRoles(user, ['ADMIN', 'APPROVER'])
    const showDrafts = userHasAnyOfRoles(user, ['ADMIN', 'USER'])
    const showExport = userHasRole(user, 'ADMIN')

    return (
        <div>
            <div>
                {consentables.length > 0 && !userDismissedConsentables && (
                    <ConsentableModal
                        consentables={consentables}
                        onConsentableAccept={onConsentableAccept}
                        setUserDismissedConsentables={
                            setUserDismissedConsentables
                        }
                    />
                )}
                {approveAllModal && (
                    <ApproveAllModal
                        timesheets={timesheets.filter(
                            (timesheet) => timesheet.state === 'submitted'
                        )}
                        onCancel={() => {
                            setApproveAllModal(false)
                            window.location.reload()
                        }}
                    />
                )}
                <Navigation user={user} />

                <div className="tabs" style={{ position: 'relative' }}>
                    <div>
                        {showDrafts && (
                            <Tab
                                id="draft"
                                label="Draft"
                                currentId={tab}
                                onTab={onTab}
                            />
                        )}
                        <Tab
                            id="toBeProcessed"
                            label="To be processed"
                            currentId={tab}
                            onTab={onTab}
                        />
                        <Tab
                            id="processed"
                            label="Processed"
                            currentId={tab}
                            onTab={onTab}
                        />
                        <Tab
                            id="didNotWork"
                            label="Did not work"
                            currentId={tab}
                            onTab={onTab}
                        />
                        {showExport && (
                            <Tab
                                id="export"
                                label="Export"
                                currentId={tab}
                                onTab={onTab}
                            />
                        )}
                    </div>
                </div>
            </div>

            {tab === 'export' && <Export />}

            {isAdminOrApprover && tab !== 'export' && (
                <div className="flex">
                    <HomePageFilters
                        nameFilter={nameFilter}
                        setNameFilter={onNameFilter}
                        showStateFilter={tab === 'toBeProcessed'}
                        stateFilter={stateFilter}
                        setStateFilter={onStateFilter}
                    />
                    {tab === 'toBeProcessed' && isAdminOrApprover && (
                        <div>
                            <button
                                id="approve-btn"
                                className="btn"
                                onClick={onApproveAll}
                            >
                                Approve All
                            </button>
                        </div>
                    )}
                </div>
            )}

            {tab === 'draft' && (
                <TimesheetList
                    timesheets={timesheets.filter(
                        (timesheet) => timesheet.state === 'draft'
                    )}
                    title="Draft cost items"
                    user={user}
                    draft={true}
                    page={page}
                    numPages={numPages}
                    onPage={onPage}
                    onDidNotWork={onDidNotWorkButton}
                    endDateSorting={endDateSortOrder}
                    onEndDateSorting={onEndDateSorting}
                />
            )}
            <div></div>

            {tab === 'toBeProcessed' && (
                <TimesheetList
                    timesheets={timesheets.filter((timesheet) =>
                        toBeProcessed(timesheet.state)
                    )}
                    title="Cost items to be processed"
                    user={user}
                    page={page}
                    numPages={numPages}
                    onPage={onPage}
                    onDidNotWork={onDidNotWorkButton}
                    endDateSorting={endDateSortOrder}
                    onEndDateSorting={onEndDateSorting}
                />
            )}
            <div></div>

            {tab === 'processed' && (
                <TimesheetList
                    timesheets={timesheets.filter(
                        (timesheet) => timesheet.state === 'processed'
                    )}
                    title="Processed cost items"
                    user={user}
                    processed={true}
                    page={page}
                    numPages={numPages}
                    onPage={onPage}
                    onDidNotWork={onDidNotWorkButton}
                    endDateSorting={endDateSortOrder}
                    onEndDateSorting={onEndDateSorting}
                />
            )}
            <div></div>

            {tab === 'didNotWork' && (
                <TimesheetList
                    timesheets={timesheets.filter(
                        (timesheet) => timesheet.state === 'did-not-work'
                    )}
                    title="Did not work cost items"
                    user={user}
                    didNotWork={true}
                    page={page}
                    numPages={numPages}
                    onPage={onPage}
                    onDidNotWork={onDidNotWorkButton}
                    endDateSorting={endDateSortOrder}
                    onEndDateSorting={onEndDateSorting}
                />
            )}
        </div>
    )
}
