import React from 'react'
import { UpdateTimesheetAction, TimesheetState } from '../types/model'
import { User } from '../types/model'
import { TimesheetInputs } from '../state/types'
import { userHasAnyOfRoles } from '../roles'

const permissions = {
    submit: {
        roles: ['USER', 'ADMIN'],
        states: ['draft'],
    },
    unsubmit: {
        roles: ['USER', 'ADMIN'],
        states: ['submitted', 'did-not-work'],
    },
    approve: {
        roles: ['ADMIN', 'APPROVER'],
        states: ['submitted'],
    },
    unapprove: {
        roles: ['ADMIN'],
        states: ['approved'],
    },
    reject: {
        roles: ['ADMIN', 'APPROVER'],
        states: ['submitted'],
    },
    process: {
        roles: ['ADMIN'],
        states: ['approved'],
    },
    unprocess: {
        roles: ['ADMIN'],
        states: ['processing'],
    },
    save: {
        roles: ['USER', 'ADMIN'],
        states: ['draft'],
    },
}

const mayUseButton = (
    user: User,
    action: UpdateTimesheetAction,
    state: TimesheetState
) => {
    const permission = permissions[action]
    if (permission.states.indexOf(state) === -1) {
        return false
    }
    return userHasAnyOfRoles(user, permission.roles)
}

interface ActionButtonProps {
    action: UpdateTimesheetAction
    label: string
    user: User
    timesheet: TimesheetInputs
    onClick: () => void
}

export const ActionButton = ({
    action,
    user,
    timesheet,
    label,
    onClick,
}: ActionButtonProps) => {
    if (mayUseButton(user, action, timesheet.state)) {
        return (
            <button className="btn" onClick={onClick}>
                {label}
            </button>
        )
    }
    return null
}
