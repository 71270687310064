import React, { useEffect, useState } from 'react'
import { authedGet, authedPost } from '../lib/util/http'

import './Export.css'

interface DateEntry {
    name: string
    value: string
}

export const Export = () => {
    const [dates, setDates] = useState([] as DateEntry[])
    const [selectedDate, setSelectedDate] = useState('')
    const [result, setResult] = useState('')
    const [error, setError] = useState('')
    const [log, setLog] = useState([])
    const [processing, setProcessing] = useState(false)

    const getDates = async () => {
        const res = await authedGet('exports/dates')
        const json = await res.json()
        const dates = json.map((yearMonth: string) => ({
            name: yearMonth,
            value: yearMonth,
        }))
        setDates(dates)
        if (dates && dates.length > 0) {
            setSelectedDate(dates[0].value)
        }
    }

    const doExport = async () => {
        if (
            !window.confirm('Are you sure? This will export data into Exact.')
        ) {
            return
        }
        setResult(
            'Running batch export. This could take some time, please be patient.'
        )
        setError('')
        setProcessing(true)
        const date = `${selectedDate}-01T00:00:00Z`
        const result = await authedPost(`exports?date=${date}`, {})
        const json = await result.json()
        setLog(json.log)
        if (result.status !== 200) {
            setResult('')
            setError(
                `Export failed, error: '${json.error}'. Not all data was exported, please validate inside Exact.`
            )
        } else {
            setResult('Export succeeded')
        }
        setProcessing(false)
    }

    useEffect(() => {
        getDates()
    }, [])

    return (
        <div className="export">
            <h1>Export</h1>
            <div>
                Month to export:&nbsp;&nbsp;
                <select
                    className="dropdown"
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                >
                    {dates.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.name}
                        </option>
                    ))}
                </select>
                <br />
                <button
                    className="btn"
                    disabled={processing}
                    onClick={doExport}
                >
                    Export to Exact
                </button>
            </div>
            <br />
            <br />
            {result && <div className="result">{result}</div>}
            {error && <div className="export-error">{error}</div>}
            <br />
            <br />
            {log.length > 0 && (
                <div className="log">
                    {log.map((line, idx) => (
                        <div key={idx}>{line}</div>
                    ))}
                </div>
            )}
        </div>
    )
}
