import React from 'react'

interface HomePageFilterProps {
  setNameFilter: Function
  nameFilter: string
  showStateFilter: boolean
  stateFilter: string
  setStateFilter: Function
}

export const HomePageFilters = ({ nameFilter, setNameFilter, showStateFilter, stateFilter, setStateFilter }: HomePageFilterProps) => {
  const stateFilterOptions = ['all', 'submitted', 'approved']

  return <div className='home-filters'>
    <div className='name-filter'>
      Filter timesheets:
      <input value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} />
    </div>

    {showStateFilter && <div className='state-filter'>Timesheet state:
      <select value={stateFilter} onChange={e => setStateFilter(e.target.value)}>
        {stateFilterOptions.map(opt =>
          <option key={opt} value={opt}>{opt.toUpperCase()}</option>
        )}
      </select>
    </div>}
  </div>
}
