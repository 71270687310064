import { createStore, combineReducers } from 'redux'
import { appReducer } from "./appReducer";
import { authenticationReducer } from "./authenticationReducer"
import { timesheetReducer } from "./timesheetReducer";

export default createStore(
  combineReducers({
    app: appReducer,
    authentication: authenticationReducer,
    timesheets: timesheetReducer
  }),
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)
