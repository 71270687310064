import * as immutable from 'object-path-immutable'
import { Action, AppState } from "./types";

const initialState: AppState = {
  page: 'login',
  isLoggedIn: false,
  user: undefined,
}

export const appReducer = (state: AppState = initialState, action: Action): AppState => {
  switch (action.type) {
    case 'USER_LOADED':
      return immutable.wrap(state)
        .set('isLoggedIn', true)
        .set('user', action.payload)
        .value()
    case 'USER_NOT_LOADED':
      return immutable.wrap(state)
        .set('isLoggedIn', false)
        .set('user', undefined)
        .value()
    case 'NAVIGATE':
      return immutable.set(state, 'page', (action.payload as any).page)
    default:
      return state
  }
}
