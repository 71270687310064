import React from 'react'

import { format } from 'date-fns'
import { getWeekNumber } from '../state/helpers'
import { Timesheet, User } from '../types/model'
import { navigate } from '../state/navigateActions'

import './TimesheetList.css'
import { userHasAnyOfRoles } from '../roles'

interface Props {
    timesheets: Timesheet[]
    title: string
    didNotWork?: boolean
    draft?: boolean
    processed?: boolean
    user: User
    page: number
    numPages?: number
    endDateSorting: string
    onPage: Function
    onEndDateSorting: Function
    onDidNotWork: Function
}

const onNavigate = (id: string) => {
    return navigate('timesheet', { id })
}

export const TimesheetList = ({
    timesheets,
    title,
    draft,
    didNotWork,
    processed,
    user,
    page,
    numPages,
    onPage,
    onDidNotWork,
    endDateSorting,
    onEndDateSorting,
}: Props) => {
    const draftOrDidNotWork = draft || didNotWork
    const showName = userHasAnyOfRoles(user, ['ADMIN', 'APPROVER'])

    return (
        <div className="timesheet-list-container">
            <div className="timesheet-list-heading">{title}</div>
            <div className="timesheet-list">
                <div className="timesheet-content">
                    <table>
                        <thead className="timesheet-list-thead">
                            <tr>
                                {showName && <th className="ts-name">Name</th>}
                                <th className="ts-small">Week</th>
                                <th className="ts-small">Nr</th>
                                <th>Starts</th>
                                <th>
                                    <button
                                        className="sort-btn"
                                        onClick={() =>
                                            onEndDateSorting(
                                                endDateSorting === 'ASC'
                                                    ? 'DESC'
                                                    : 'ASC'
                                            )
                                        }
                                    >
                                        Ends <span>↕</span>
                                    </button>
                                </th>
                                {draft && (
                                    <React.Fragment>
                                        <th>Worked</th>
                                        <th></th>
                                    </React.Fragment>
                                )}
                                {!draftOrDidNotWork && (
                                    <React.Fragment>
                                        <th>Worked</th>
                                        <th>Exp.</th>
                                        <th>KM</th>
                                        <th>
                                            {processed ? 'Processed' : 'Status'}
                                        </th>
                                        <th className="ts-name">Approver</th>
                                    </React.Fragment>
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {timesheets.length === 0 && (
                                <tr>
                                    <th colSpan={3}>No cost items</th>
                                </tr>
                            )}
                            {timesheets.map((timesheet, _idx) => {
                                const processedDate = timesheet.updatedAt
                                const processedDateDmy =
                                    processedDate &&
                                    format(
                                        new Date(processedDate),
                                        'dd-MM-yyyy'
                                    )
                                const processedDateDmyHm =
                                    processedDate &&
                                    format(
                                        new Date(processedDate),
                                        'dd-MM-yyyy HH:MM'
                                    )

                                return (
                                    <tr
                                        className="timesheet-list-row"
                                        key={timesheet.id}
                                        onClick={(e) =>
                                            onNavigate(timesheet.id)
                                        }
                                    >
                                        {showName && (
                                            <td>{timesheet.Owner.name}</td>
                                        )}
                                        <td>
                                            {getWeekNumber(timesheet.startDate)}
                                        </td>
                                        <td>{timesheet.id}</td>
                                        <td>
                                            {format(
                                                new Date(timesheet.startDate),
                                                'dd-MM-yyyy'
                                            )}
                                        </td>
                                        <td>
                                            {format(
                                                new Date(timesheet.endDate),
                                                'dd-MM-yyyy'
                                            )}
                                        </td>
                                        {!didNotWork && (
                                            <td>{timesheet.worked}</td>
                                        )}
                                        {draft && (
                                            <td className="ts-dnw">
                                                <button
                                                    onClick={(e) =>
                                                        onDidNotWork(
                                                            e,
                                                            timesheet.id
                                                        )
                                                    }
                                                >
                                                    Did not work
                                                </button>
                                            </td>
                                        )}
                                        {!draftOrDidNotWork && (
                                            <td>
                                                €{' '}
                                                {timesheet.expenses &&
                                                    timesheet.expenses.toFixed(
                                                        2
                                                    )}
                                            </td>
                                        )}
                                        {!draftOrDidNotWork && (
                                            <td>
                                                {timesheet.travel &&
                                                    timesheet.travel.toFixed(2)}
                                            </td>
                                        )}
                                        {processed && (
                                            <td title={processedDateDmyHm}>
                                                {processedDateDmy}
                                            </td>
                                        )}
                                        {!draftOrDidNotWork && !processed && (
                                            <td>
                                                {timesheet.state.toUpperCase()}
                                            </td>
                                        )}
                                        {!draftOrDidNotWork && (
                                            <td>
                                                {timesheet.LastApprover &&
                                                    timesheet.LastApprover.name}
                                            </td>
                                        )}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                {numPages && numPages > 1 && (
                    <div className="pagination">
                        <button
                            disabled={page === 0}
                            className="previous-page"
                            onClick={(e) => onPage(page - 1)}
                        >
                            &lt; Previous
                        </button>
                        Page {page + 1} / {numPages}
                        <button
                            disabled={page === numPages - 1}
                            className="next-page"
                            onClick={(e) => onPage(page + 1)}
                        >
                            Next &gt;
                        </button>
                    </div>
                )}
            </div>
        </div>
    )
}
