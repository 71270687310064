import { urlParamsToMap } from "./urls";

export const getApiUrl = () => {
  const isRunningLocally =
      window.location.port === '3000'
    || window.location.port === '3001'
    || window.location.port === '3002'
  return isRunningLocally ? 'http://localhost:3210/api' : '/api'
}

const config = {
  baseUrl: getApiUrl()
}

const getDebug = (): string => {
  if (window.location.search.indexOf('debug=true') !== -1) {
    localStorage.setItem('debug', 'true')
    return 'true'
  }
  if (window.location.search.indexOf('debug=false') !== -1) {
    localStorage.setItem('debug', 'false')
    return 'false'
  }
  const storedVal = localStorage.getItem('debug')
  if (storedVal) {
    return storedVal
  }
  return 'false'
}

const getLoginToken = (): string => {
  const params = urlParamsToMap(window.location.search)
  return params.loginToken || ''
}

const getSessionId = (): string => {
  let sessionId = ''
  const params = urlParamsToMap(window.location.search)
  if (params.cookie) {
    const sessionId = params.cookie
    if (!sessionId) {
      return ''
    }
    localStorage.setItem('sessionId', sessionId)
    return sessionId
  }
  sessionId = localStorage.getItem('sessionId') || ''
  return sessionId
}

export const authedGet = async (path: string) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method: 'GET',
    headers: {
      'X-authenticated': getSessionId(),
      'X-login-token': getLoginToken(),
      'X-debug': getDebug()
    }
  })
  return result
}

const authedMutate = async (method: string, path: string, body: object) => {
  const result = await fetch(`${config.baseUrl}/${path}`, {
    method,
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'X-authenticated': getSessionId(),
      'X-login-token': getLoginToken(),
      'X-debug': getDebug()
    },
    body: JSON.stringify(body)
  })
  return result
}

export const authedDelete = async (path: string, body: object) => {
  return await authedMutate('DELETE', path, body)
}

export const authedPut = async (path: string, body: object) => {
  return await authedMutate('PUT', path, body)
}

export const authedPost = async (path: string, body: object) => {
  return await authedMutate('POST', path, body)
}
