import React from 'react'
import './Tab.css'

interface TabProps {
  id: string;
  label: string
  currentId: string;
  onTab: Function;
}

export const Tab = ({ id, label, currentId, onTab}: TabProps) => {
  return <button
    className={id === currentId ? 'selected' : ''}
    onClick={e => onTab(id)}
  >
    {label}
  </button>

}
