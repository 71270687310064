import React from 'react'

import './Modal.css'

export const Modal = props => <div>
  <div className='modal overlay'></div>
    <div className='modal container'>
      <div className='modal dialog'>
        {props.children}
      </div>
    </div>
  </div>
