import React, { Component } from 'react'
import './App.css'
import './lib/ui/ui.css'

import store from './state/store'
import { loadUser } from './state/asyncUserActions'

import { urlParamsToMap } from './lib/util/urls'
import { AppState, TimesheetsState, AuthenticationState } from "./state/types";
import { LoginPage } from "./components/LoginPage";

import { SetPasswordPage } from "./components/SetPasswordPage";
import { HomePage } from "./components/HomePage";
import { navigate } from "./state/navigateActions";
import { TimesheetPage } from "./components/TimesheetPage";
import { ResetPasswordPage } from "./components/ResetPasswordPage";

let unsubscribe: any

interface Props {}

interface ReduxProps {
  app: AppState
  authentication: AuthenticationState
  timesheets: TimesheetsState
}

const niceRoutes = false

class App extends Component<Props, ReduxProps> {
  async componentDidMount() {
    // first of all, load the current user - we won't do anything if they are not logged in
    await loadUser()

    unsubscribe = store.subscribe(() => {
      this.setStateFromStore()
    })

    this.setStateFromStore()

    this.setPageFromUrl()

    window.onpopstate = this.setPageFromUrl
  }

  componentWillUnmount () {
    unsubscribe()
  }

  setStateFromStore () {
    this.setState({
      ...this.state,
      ...store.getState()
    })
  }

  setPageFromUrl = () => {
    let page = window.location.pathname.slice(1) || 'login'
    const params = urlParamsToMap(window.location.search)

    if (!niceRoutes) {
      page = params.page ? params.page : 'login'
    }

    navigate(page, params)
  }

  render() {
    if (!this.state) {
      return <div>Loading...</div>
    }
    const app = this.state.app
    const page = app.page

    return <div className='copra-container'>
      {page === 'login' && <LoginPage authentication={this.state.authentication} />}
      {page === 'reset-password' && <ResetPasswordPage authentication={this.state.authentication} />}
      {page === 'timesheet' && <TimesheetPage app={app} timesheets={this.state.timesheets} />}
      {page === 'set-password' && <SetPasswordPage authentication={this.state.authentication} />}
      {page === 'home' && <HomePage app={app} timesheets={this.state.timesheets} />}
    </div>
  }
}

export default App;
