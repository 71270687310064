import React from 'react'

import '../lib/ui/Modal.css'
import { getApiUrl } from "../lib/util/http"
import { Consentable } from "../state/types"
import './ConsentableModal.css'

interface Props {
  consentables: Consentable[]
  onConsentableAccept: Function
  setUserDismissedConsentables: Function
}

export const ConsentableModal = (props: Props) => {

  const firstConsentable = props.consentables[0]
  const url = `${getApiUrl()}/documents/?url=${firstConsentable.url}#view=FitH`
  return <React.Fragment>
    <div className='modal overlay'></div>
    <div className='modal container'>
      <div className='view-document'>
        <object data={url} type="application/pdf">
          <embed src={url} type="application/pdf" />
        </object>
        <div className='buttons'>
        <button
          onClick={e => props.onConsentableAccept(firstConsentable.document)}
          className='btn'>Read and understood</button>
        <button
          onClick={e => props.setUserDismissedConsentables(true)}
          className='btn'>Read later</button>
        </div>
      </div>
      
    </div>
  </React.Fragment>
}
