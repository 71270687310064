import { User } from './types/model'

export const userHasRole = (user: User, role: string) => {
    const userRoles = user.roles.split(',')
    return userRoles.includes(role)
}

export const userHasAnyOfRoles = (user: User, roles: string[]) => {
    const userRoles = user.roles.split(',')
    const any = userRoles.find((userRole) => roles.includes(userRole))
    return !!any
}
