import React from 'react'

import { DayInput } from '../state/types'
import { TextNumberInput } from './TextNumberInput'
import { ToolTip } from '../lib/ui/ToolTip'

type DayInputKey = keyof DayInput

interface Props {
  label: string
  valueKey: DayInputKey
  canUserEdit: boolean
  days: DayInput[]
  tooltipText?: string
  onChange: (value: string, idx: number) => void
}

export const TimesheetDayInputRow = ({ label, valueKey, canUserEdit, days, tooltipText, onChange }: Props) => {
  return <React.Fragment>
    <td>
      {label}
      {tooltipText && <ToolTip text={tooltipText} />}
    </td>
    {days.map((day, idx) => {
        if (!day.dayId) {
          return <td key={idx}></td>
        }
      
        return <td key={idx} className='timesheet-col'>
          <TextNumberInput
            readonly={!canUserEdit}
            value={day[valueKey] || ''}
            decimals={2}
            onChange={(value: string) => onChange(value, idx)} />
        </td>
      }
    )}
  </React.Fragment>
}
