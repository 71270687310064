import { action } from './action'
import { buildUrlFromArgs } from "../lib/util/urls";
import { StringMap } from "../lib/types/generic";

const niceRoutes = false

export const navigateViaLogin = () => {
  localStorage.setItem('redirect', window.location.href)
  navigate('login')
}

export const navigate = (page: string, params: StringMap = {}) => {
  let url

  if (niceRoutes) {
    url = buildUrlFromArgs(page, params)
  } else {
    params.page = page
    url = buildUrlFromArgs('', params)
  }

  window.history.pushState({}, '', '/' + url)

  action('NAVIGATE', { page })
}
