import { UpdateTimesheetAction, User } from "../types/model";
import { authedGet, authedPut, authedPost } from "../lib/util/http";
import { action } from "./action";
import { TimesheetInputs } from "./types";

interface FilterOptions {
  stateFilter?: string
  nameFilter?: string
}

export type SortOrder = 'ASC' | 'DESC'

export const onUpdateTimesheet = async (timesheet: TimesheetInputs, timesheetAction: UpdateTimesheetAction) => {
  const request = {
    timesheet,
    action: timesheetAction
  }
  const result = await authedPut(`timesheets/${timesheet.id}`, request)
  if (result.status !== 200) {
    const errResp = await result.json()
    return action('TIMESHEET_UPDATE_FAILED', errResp.error)
  }
  const updatedTimesheet = await result.json()
  action('TIMESHEET_LOADED', {})
  action('TIMESHEET_LOADED', updatedTimesheet)
}

export const onSaveTimesheet = async (timesheet: TimesheetInputs) => {
  const request = {
    timesheet
  }
  const result = await authedPut(`timesheets/${timesheet.id}`, request)
  if (result.status !== 200) {
    const errResp = await result.json()
    return action('TIMESHEET_UPDATE_FAILED', errResp.error)
  }
  const updatedTimesheet = await result.json()
  action('TIMESHEET_LOADED', {})
  action('TIMESHEET_LOADED', updatedTimesheet)
  action('TIMESHEET_SAVED', 'Saved successfully.' as unknown as object)
}

export const loadTimesheetsForUser = async (
  tab: string,
  page: number,
  filterOptions: FilterOptions,
  endDateSortOrder: SortOrder
) => {
  let url = `users/current/timesheets?type=${tab}&page=${page}&endDateSortOrder=${endDateSortOrder}`
  if (filterOptions.nameFilter) {
    url += `&nameFilter=${filterOptions.nameFilter}`
  }
  if (filterOptions.stateFilter) {
    url += `&stateFilter=${filterOptions.stateFilter}`
  }
  const result = await authedGet(url)
  if (result.status !== 200) {
    return action('TIMESHEETS_FOR_USER_FAILED')
  }
  const timesheets = await result.json()
  action('TIMESHEETS_LOADED', timesheets.data)
  action('TIMESHEETS_TOTALS_LOADED', timesheets.total)
}

export const onDidNotWork = async (timesheetId: string) => {
  const result = await authedPost(`timesheets/${timesheetId}/did-not-work`, {})
  if (result.status !== 200) {
    return action('TIMESHEET_UPDATE_FAILED')
  }
}

export const loadTimesheet = async (id: string) => {
  const result = await authedGet(`timesheets/${id}`)
  const json = await result.json()
  if (result.status !== 200) {
    const error = json.error
    return action('TIMESHEET_LOAD_FAILED', error)
  }
  action('TIMESHEET_LOADED', json)
}