import { authedGet, authedPut } from "../lib/util/http";

export const acceptConsentable = async (document: string) => {
  const result = await authedPut(`consentables?document=${document}`, {})
  if (result.status !== 200) {
    throw new Error('Cannot accept consentable')
  }
  const response = await result.json()
  return response.data
}

export const loadConsentablesForUser = async () => {
  const result = await authedGet(`users/current/consentables`)
  if (result.status !== 200) {
    throw new Error('Cannot load consentables')
  }
  const response = await result.json()
  return response.data
}