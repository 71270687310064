import React, { useEffect } from 'react'

import './Timesheet.css'
import { TimesheetsState, AppState } from "../state/types";
import { urlParamsToMap } from "../lib/util/urls";
import { loadTimesheet } from "../state/asyncTimesheetActions";
import { TimesheetView } from "./TimesheetView";
import { Navigation } from "./Navigation";
import { navigateViaLogin } from "../state/navigateActions";

interface Props {
  timesheets: TimesheetsState
  app: AppState
}

export const TimesheetPage = (props: Props) => {
  const user = props.app.user
  const params = urlParamsToMap(window.location.search)

  const timesheet = props.timesheets.currentTimesheet

  useEffect(() => {
    if (user) {
      loadTimesheet(params.id)
    } else if (!user) {
      navigateViaLogin()
    }
  }, [user, params.id])

  if (props.timesheets.error) {
    return <div>{props.timesheets.error}</div>
  }
  if (!timesheet || !timesheet.TimesheetDays) {
    return <div>Loading...</div>
  }

  if (!user) {
    return <div>Please login first.</div>
  }

  return <div>
    <Navigation user={user} />

    <TimesheetView message={props.timesheets.message} timesheet={timesheet} user={user} />
  </div>
}
