import React, { useState } from 'react'
import './LoginPage.css'
import logo from './COPRA-logo.png'
import { resetPasswordUser } from "../state/asyncUserActions";
import { AuthenticationState } from "../state/types";

interface Props {
  authentication: AuthenticationState
}

export const ResetPasswordPage = ({ authentication }: Props) => {
  const [email, setEmail] = useState('')

  return <div className='wrapper'>
  <div className='login-container'>
    <div className='login-form'>
      <form onSubmit={(e) => {
          e.preventDefault()
          resetPasswordUser(email)
        }}>
           <img src="/Magno_logo.png" alt='COPRA Logo' className='logo' />
        <h1>Reset password</h1>

        {authentication.error &&
          <h2 className='login-error'>{authentication.error}</h2>}
        {authentication.result &&
          <h2 className='login-error'>{authentication.result}</h2>}

        <label>
          Email
          <input placeholder='myemail@example.com' value={email} onChange={(e) => setEmail(e.target.value)} />
        </label>
        <button type='submit' className='forgot-link'>Reset password</button>
        <div className='clear'></div>
      </form>
    </div>
  </div>
  </div>
}
