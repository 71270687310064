import { StringMap } from '../types/generic'

export const urlParamsToMap = (urlParams: string): StringMap => {
  if (!urlParams) {
    return {}
  }
  const paramsArray = urlParams.slice(1).split('&')
  const params = paramsArray.reduce((prev: StringMap, curr) => {
    const pair = curr.split('=')
    prev[pair[0]] = pair[1]
    return prev
  }, {})
  return params
}

export const buildUrlFromArgs = (page: string, args: StringMap): string => {
  const urlParams = Object.keys(args).map(key => `${key}=${args[key]}`).join('&')
  let url = page
  if (urlParams) {
    url += `?${urlParams}`
  }
  return url
}