import React, { useState } from 'react'

import '../lib/ui/Modal.css'
import { Timesheet } from '../types/model'
import './ApproveAllModal.css'
import { onUpdateTimesheet } from '../state/asyncTimesheetActions'

interface Props {
    timesheets: Timesheet[]
    onCancel: () => void
}

export const ApproveAllModal = ({ timesheets }: Props) => {
    const [approvedCount, setApprovedCount] = useState(0)
    const onCancel = async () => {
        window.location.reload()
    }
    const onApproveConfirm = async () => {
        for (const timesheet of timesheets) {
            await onUpdateTimesheet({ ...timesheet, days: [] }, 'approve')
            setApprovedCount((c) => c + 1)
        }
    }
    return (
        <React.Fragment>
            <div className="modal overlay"></div>
            <div className="modal container">
                <div className="approve-all-timesheets">
                    <h3>Approve All</h3>
                    <div>
                        This will approve all timesheets in 'SUBMITTED' state.
                        This is a high impact action and cannot be undone.
                    </div>
                    <div className="total-timesheets">
                        Total submitted timesheets: {timesheets.length}
                    </div>
                    <div>
                        Please verify all of the timesheets should be approved
                        before proceeding.
                    </div>
                    <div className="total-timesheets">
                        Progress: {approvedCount} / {timesheets.length}
                    </div>
                    <div className="buttons timesheets-btns">
                        <button
                            disabled={approvedCount === timesheets.length}
                            onClick={() => onApproveConfirm()}
                            className="btn"
                        >
                            Confirm
                        </button>
                        <button onClick={() => onCancel()} className="btn">
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
