import React, { useState, useEffect } from 'react'
import { format, getDay } from 'date-fns'

import { TimeUnitInput } from './TimeUnitInput'
import { ActionButton } from './WeekActionButtons'
import { navigate } from '../state/navigateActions'

import './Timesheet.css'

import { Timesheet, User } from '../types/model'
import { TimesheetInputs, DayInput } from '../state/types'
import { TimesheetDayInputRow } from './TimesheetDayInputRow'
import { RejectModal } from './RejectModal'
import { Modal } from '../lib/ui/Modal'
import {
    onSaveTimesheet,
    onUpdateTimesheet,
} from '../state/asyncTimesheetActions'
import { getWeekNumber, sumDayTotals } from '../state/helpers'
import { userHasRole } from '../roles'

interface Props {
    timesheet: Timesheet
    user: User
    message?: string
}

const orEmptyString = (value: number | null) => {
    return value === null || isNaN(value) ? '' : String(value)
}

const mapToInputs = (timesheet: Timesheet): TimesheetInputs => {
    const inputs = {
        id: timesheet.id,
        state: timesheet.state,
        days: [],
    } as TimesheetInputs

    for (let i = 0; i < 7; i++) {
        const day = timesheet.TimesheetDays.find(
            (day) => getDay(new Date(day.workDate)) === (i + 1) % 7
        )
        if (day) {
            const dayInput = {
                dayId: day.id,
                workDate: day.workDate,
                worked: orEmptyString(day.worked),
                expenses: orEmptyString(day.expenses),
                travel: orEmptyString(day.travel),
                paidLeave: orEmptyString(day.paidLeave),
                expensesDeductible: orEmptyString(day.expensesDeductible),
                travelDeductible: orEmptyString(day.travelDeductible),
                travelExpensesDeductible: orEmptyString(
                    day.travelExpensesDeductible
                ),
            } as DayInput

            inputs.days.push(dayInput)
        } else {
            inputs.days.push({
                dayId: '',
                workDate: '',
            })
        }
    }

    return inputs
}

const onClickReject = (setModalAction: Function) => {
    setModalAction('reject')
}

const onSubmitRejectModal = (timesheet: TimesheetInputs, remark: string) => {
    timesheet.remark = remark
    onUpdateTimesheet(timesheet, 'reject')
}

const days = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
]

const canUserEdit = (user: User, timesheet: Timesheet) => {
    return (
        timesheet.state === 'draft' &&
        (timesheet.ownerId === user.id || userHasRole(user, 'ADMIN'))
    )
}

type DayInputKey = 'worked' | 'paidLeave'

const validateWorkedOrLeave = (day: DayInput, timeUnit: string) => {
    if (!day.worked || !day.paidLeave) {
        return true
    }
    if (timeUnit === 'days') {
        if (Number(day.worked) + Number(day.paidLeave) > 1.0) {
            return false
        }
    }
    if (timeUnit === 'hours') {
        if (Number(day.worked) + Number(day.paidLeave) > 8.0) {
            return false
        }
    }
    return true
}

type Validations = {
    [index: string]: boolean
}

const anyInvalidDays = (validations: Validations) => {
    return Object.keys(validations).find((key) => validations[key] === false)
}

export const TimesheetView = (props: Props) => {
    const [state, setState] = useState<TimesheetInputs>(
        mapToInputs(props.timesheet)
    )
    const [modalAction, setModalAction] = useState<string>('')
    const [validations, setValidations] = useState({} as Validations)
    const [error, setError] = useState('')

    const setWorkedOrLeave = (
        key: DayInputKey,
        day: DayInput,
        value: string
    ) => {
        const newState = { ...state }
        day[key] = value
        setState(newState)
        const isDayValid = validateWorkedOrLeave(
            day,
            props.timesheet.AVProject.UnitType
        )
        const newValidations = { ...validations, [day.dayId]: isDayValid }
        setValidations(newValidations)
        if (!anyInvalidDays(newValidations)) {
            setError('')
        }
    }

    const onSubmitTimesheet = () => {
        if (anyInvalidDays(validations)) {
            return setError(
                'Invalid days: one or more days have too much time allocated to worked and leave'
            )
        }
        onUpdateTimesheet(state, 'submit')
    }

    useEffect(() => {
        setState(mapToInputs(props.timesheet))
    }, [props.timesheet])

    const totals = sumDayTotals(state.days)

    const isApprover = userHasRole(props.user, 'APPROVER')
    const isAdmin = userHasRole(props.user, 'ADMIN')

    const shouldShowPaidLeave =
        props.timesheet.AVProject.PaidLeave && !isApprover
    const shouldShowTravelExpensesDeductible =
        props.timesheet.AVProject.TravelExpensesDeductible && !isApprover
    const shouldShowExpensesDeductible =
        props.timesheet.AVProject.ExpensesDeductible && !isApprover
    const shouldShowTravelDeductible =
        props.timesheet.AVProject.TravelDeductible && !isApprover

    const approverLabel =
        props.timesheet.Approvers.length > 1
            ? 'Project approver'
            : 'Project approvers'
    const approvers = props.timesheet.Approvers.map(
        (approver) => approver.name
    ).join(', ')

    return (
        <div>
            {modalAction && (
                <Modal>
                    <RejectModal
                        onCancel={() => setModalAction('')}
                        onSubmit={(result: string) =>
                            onSubmitRejectModal(state, result)
                        }
                    />
                </Modal>
            )}
            <div className="timesheet-container">
                <div className="timesheet-header">
                    <div className="col-50">
                        <h2>Status: {props.timesheet.state.toUpperCase()}</h2>
                    </div>
                </div>
                <div className="timesheet-section">
                    <div className="col-50 ts-info">
                        <div>
                            Professional name: {props.timesheet.Owner.name}
                            {isAdmin && (
                                <a
                                    className="mail-link icon-mail"
                                    href={`mailto:${props.timesheet.Owner.email}`}
                                ></a>
                            )}
                        </div>
                        <div>
                            {approverLabel}: {approvers}
                        </div>
                    </div>
                    <div className="col-50 ts-info">
                        <div>Cost item: {props.timesheet.id}</div>
                        <div>{props.timesheet.AVProject.MagnoEntity}</div>
                    </div>

                    <table className="timesheet">
                        <thead>
                            <tr>
                                <th>Week #</th>
                                {days.map((day) => (
                                    <th key={day}>{day}</th>
                                ))}
                                <th></th>
                            </tr>
                            <tr>
                                <th>
                                    {getWeekNumber(props.timesheet.startDate)}
                                </th>
                                {state.days.map((day, idx) => {
                                    if (!day.dayId) {
                                        return <th key={idx}></th>
                                    }
                                    return (
                                        <th key={idx}>
                                            {format(
                                                new Date(day.workDate),
                                                'dd-M-yyyy'
                                            )}
                                        </th>
                                    )
                                })}
                                <th>Total:</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {props.timesheet.AVProject.UnitType ===
                                    'days'
                                        ? 'Days'
                                        : 'Hours'}{' '}
                                    worked
                                </td>
                                {state.days.map((day, idx) => {
                                    if (!day.dayId) {
                                        return <td key={idx}></td>
                                    }
                                    const isDayValid =
                                        validations[day.dayId] !== false
                                    return (
                                        <td key={idx} className="timesheet-col">
                                            <div
                                                className="input-validator"
                                                style={{
                                                    border: isDayValid
                                                        ? '1px solid transparent'
                                                        : '1px solid red',
                                                }}
                                            >
                                                <TimeUnitInput
                                                    readonly={
                                                        !canUserEdit(
                                                            props.user,
                                                            props.timesheet
                                                        )
                                                    }
                                                    value={day.worked || ''}
                                                    unit={
                                                        props.timesheet
                                                            .AVProject.UnitType
                                                    }
                                                    onChange={(value: string) =>
                                                        setWorkedOrLeave(
                                                            'worked',
                                                            day,
                                                            value
                                                        )
                                                    }
                                                />
                                            </div>
                                        </td>
                                    )
                                })}
                                <td className="timesheet-col" id="total-number">
                                    {totals.totalWorked.toFixed(2)}
                                </td>
                            </tr>
                            {props.timesheet.AVProject.ExpensesReimbursed && (
                                <tr>
                                    <TimesheetDayInputRow
                                        label={'Reimbursable expenses (€)'}
                                        valueKey={'expenses'}
                                        canUserEdit={canUserEdit(
                                            props.user,
                                            props.timesheet
                                        )}
                                        days={state.days}
                                        tooltipText={
                                            'Expenses that the endclient has accepted for reimbursement by the endclient'
                                        }
                                        onChange={(
                                            value: string,
                                            idx: number
                                        ) => {
                                            const newState = { ...state }
                                            newState.days[idx].expenses = value
                                            setState(newState)
                                        }}
                                    />
                                    <td className="timesheet-col">
                                        € {totals.totalExpenses.toFixed(2)}
                                    </td>
                                </tr>
                            )}
                            {props.timesheet.AVProject.TravelReimbursed && (
                                <tr>
                                    <TimesheetDayInputRow
                                        label={'Reimbursable travel (km)'}
                                        valueKey={'travel'}
                                        canUserEdit={canUserEdit(
                                            props.user,
                                            props.timesheet
                                        )}
                                        days={state.days}
                                        tooltipText={
                                            'Driven kilometres that the endclient has accepted for reimbursement by the endclient'
                                        }
                                        onChange={(
                                            value: string,
                                            idx: number
                                        ) => {
                                            const newState = { ...state }
                                            newState.days[idx].travel = value
                                            setState(newState)
                                        }}
                                    />
                                    <td className="timesheet-col">
                                        {totals.totalTravel.toFixed(2)} km
                                    </td>
                                </tr>
                            )}
                            {shouldShowPaidLeave && (
                                <tr className="paid-leave-row">
                                    <td>Paid leave</td>
                                    {state.days.map((day, idx) => {
                                        if (!day.dayId) {
                                            return <td key={idx}></td>
                                        }
                                        const isDayValid =
                                            validations[day.dayId] !== false
                                        return (
                                            <td
                                                key={idx}
                                                className="timesheet-col"
                                            >
                                                <div
                                                    className="input-validator"
                                                    style={{
                                                        border: isDayValid
                                                            ? '1px solid transparent'
                                                            : '1px solid red',
                                                    }}
                                                >
                                                    <TimeUnitInput
                                                        readonly={
                                                            !canUserEdit(
                                                                props.user,
                                                                props.timesheet
                                                            )
                                                        }
                                                        value={
                                                            day.paidLeave || ''
                                                        }
                                                        unit={
                                                            props.timesheet
                                                                .AVProject
                                                                .UnitType
                                                        }
                                                        onChange={(
                                                            value: string
                                                        ) =>
                                                            setWorkedOrLeave(
                                                                'paidLeave',
                                                                day,
                                                                value
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </td>
                                        )
                                    })}
                                    <td className="timesheet-col">
                                        {totals.totalPaidLeave.toFixed(2)}
                                    </td>
                                </tr>
                            )}
                            {shouldShowTravelExpensesDeductible && (
                                <tr>
                                    <TimesheetDayInputRow
                                        label={
                                            'Deductible travel expenses* (€)'
                                        }
                                        valueKey={'travelExpensesDeductible'}
                                        canUserEdit={canUserEdit(
                                            props.user,
                                            props.timesheet
                                        )}
                                        days={state.days}
                                        tooltipText={
                                            'All work related travel expenses that are not reimbursed by the endclient (home-office, visit to home country, etc)'
                                        }
                                        onChange={(
                                            value: string,
                                            idx: number
                                        ) => {
                                            const newState = { ...state }
                                            newState.days[
                                                idx
                                            ].travelExpensesDeductible = value
                                            setState(newState)
                                        }}
                                    />
                                    <td className="timesheet-col">
                                        €{' '}
                                        {totals.totalTravelExpensesDeductible.toFixed(
                                            2
                                        )}
                                    </td>
                                </tr>
                            )}
                            {shouldShowExpensesDeductible && (
                                <tr>
                                    <TimesheetDayInputRow
                                        label={'Other deductible expenses* (€)'}
                                        valueKey={'expensesDeductible'}
                                        canUserEdit={canUserEdit(
                                            props.user,
                                            props.timesheet
                                        )}
                                        days={state.days}
                                        tooltipText={
                                            'All other work/profession related expenses that are not reimbursed by the endclient'
                                        }
                                        onChange={(
                                            value: string,
                                            idx: number
                                        ) => {
                                            const newState = { ...state }
                                            newState.days[
                                                idx
                                            ].expensesDeductible = value
                                            setState(newState)
                                        }}
                                    />
                                    <td className="timesheet-col">
                                        €{' '}
                                        {totals.totalExpensesDeductible.toFixed(
                                            2
                                        )}
                                    </td>
                                </tr>
                            )}
                            {shouldShowTravelDeductible && (
                                <tr>
                                    <TimesheetDayInputRow
                                        label={'Deductible travel (km)'}
                                        valueKey={'travelDeductible'}
                                        canUserEdit={canUserEdit(
                                            props.user,
                                            props.timesheet
                                        )}
                                        days={state.days}
                                        tooltipText={
                                            'All work related driven kilometers that are not reimbursed by the endclient (home-office, etc)'
                                        }
                                        onChange={(
                                            value: string,
                                            idx: number
                                        ) => {
                                            const newState = { ...state }
                                            newState.days[
                                                idx
                                            ].travelDeductible = value
                                            setState(newState)
                                        }}
                                    />
                                    <td className="timesheet-col">
                                        {totals.totalTravelDeductible.toFixed(
                                            2
                                        )}{' '}
                                        km
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                {error && <div className="timesheet-error">{error}</div>}
                {props.message && (
                    <div className="timesheet-message">{props.message}</div>
                )}
            </div>
            <div className="timesheet-actions">
                <div>
                    <ActionButton
                        action="submit"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onSubmitTimesheet()}
                        label="Submit"
                    />
                    <ActionButton
                        action="unsubmit"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onUpdateTimesheet(state, 'unsubmit')}
                        label="Unsubmit"
                    />
                    <ActionButton
                        action="approve"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onUpdateTimesheet(state, 'approve')}
                        label="Approve"
                    />
                    <ActionButton
                        action="unapprove"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onUpdateTimesheet(state, 'unapprove')}
                        label="Unapprove"
                    />
                    <ActionButton
                        action="reject"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onClickReject(setModalAction)}
                        label="Reject"
                    />
                    <ActionButton
                        action="process"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onUpdateTimesheet(state, 'process')}
                        label="Process"
                    />
                    <ActionButton
                        action="unprocess"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onUpdateTimesheet(state, 'unprocess')}
                        label="Unprocess"
                    />
                    <ActionButton
                        action="save"
                        user={props.user}
                        timesheet={state}
                        onClick={() => onSaveTimesheet(state)}
                        label="Save"
                    />
                    {props.user.loginTokenAuthorized || (
                        <button
                            id="back-btn"
                            className="btn"
                            onClick={(e) => navigate('home')}
                        >
                            Back
                        </button>
                    )}
                </div>
                {(props.timesheet.AVProject.TravelExpensesDeductible ||
                    props.timesheet.AVProject.ExpensesDeductible) && (
                    <div className="receipts-label">
                        * Please submit your receipts to{' '}
                        <a href="mailto:backoffice@magno-it.nl">
                            backoffice@magno-it.nl
                        </a>
                    </div>
                )}
            </div>
            <div>
                <ul>
                    {props.timesheet.TimesheetActions.map((action, idx) => (
                        <li key={idx}>
                            {action.action.toUpperCase()} by {action.User.name}{' '}
                            on{' '}
                            {format(new Date(action.createdAt), 'dd-MM-yyyy')}
                            {action.actionRemark && (
                                <div>{action.actionRemark}</div>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}
