import * as immutable from 'object-path-immutable'
import { Action, TimesheetsState } from "./types";

const initialState: TimesheetsState = {
  timesheets: [],
  total: undefined,
  currentTimesheet: undefined,
  error: undefined,
  message: undefined
}

export const timesheetReducer = (state: TimesheetsState = initialState, action: Action): TimesheetsState => {
  switch (action.type) {
    case 'TIMESHEET_UPDATE_FAILED':
      return immutable.set(state, 'error', action.payload)
    case 'TIMESHEETS_BY_OWNER_LOADED':
    case 'TIMESHEETS_LOADED':
      return immutable.wrap(state)
        .set('timesheets', action.payload)
        .set('message', undefined)
        .value()
    case 'TIMESHEET_SAVED':
      return immutable.set(state, 'message', action.payload)
    case 'TIMESHEET_LOADED':
      return immutable.set(state, 'currentTimesheet', action.payload)
    case 'TIMESHEET_LOAD_FAILED':
      return immutable.set(state, 'error', action.payload)
    case 'TIMESHEETS_TOTALS_LOADED':
      return immutable.set(state, 'total', action.payload)
    default:
      return state
  }
}
